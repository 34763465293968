import React from "react";

const NotFoundPage = () => {
  return (
    <div className="page">
      <p
        style={{
          fontFamily: "RobotoMono",
          fontSize: "24px",
          marginLeft: "4%",
          marginTop: "4%",
          color: "black",
          width: "70%",
        }}
      >
        404 not found! 404 not found! 404 not found! 404 not found! 404 not
        found! 404 not found! 404 not found! 404 not found! 404 not found! 404
        not found! 404 not found! 404 not found! 404 not found! 404 not found!
        404 not found! 404 not found! 404 not found! 404 not found! 404 not
        found! 404 not found! 404 not found! 404 not found! 404 not found! 404
        not found! 404 not found! 404 not found! 404 not found! 404 not found!
        404 not found! 404 not found! 404 not found! 404 not found! 404 not
        found! 404 not found! 404 not found! 404 not found! 404 not found! 404
        not found! 404 not found! 404 not found! 404 not found! 404 not found!
        404 not found! 404 not found! 404 not found! 404 not found! 404 not
        found! 404 not found! 404 not found! 404 not found! 404 not found! 404
        not found! 404 not found! 404 not found! 404 not found! 404 not found!
        <br />
        <br />{" "}
      </p>
    </div>
  );
};

export default NotFoundPage;
