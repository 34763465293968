import React from "react";
import { useMediaQuery } from "react-responsive";

const CopyRightFooter = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width:800px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let margTop, fontSize;

  if (isBigScreen) {
    fontSize = "1rem";
  } else {
    fontSize = "0.8rem";
  }

  if (isMobileDevice) {
    margTop = "6%";
  } else {
    margTop = "3%";
  }

  const FooterStyle = {
    marginTop: margTop,
    marginBottom: "1%",

    fontFamily: "RobotoMono",
    fontSize: fontSize,
    color: "black",
    transform: "translate(-50%, 0)",
    marginLeft: "50%",
    textAlign: "center",
    width: "80%",
  };

  return (
    <>
      <div style={FooterStyle}>
        © Copyright 2020 Noah Edelstein. All Rights Reserved.
      </div>
    </>
  );
};

export default CopyRightFooter;
