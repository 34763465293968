import React from "react";
import Resume from "../assets/NE_resume.pdf";
import { useMediaQuery } from "react-responsive";

import CopyrightFooter from "../components/CopyrightFooter";
import CopyRightFooter from "../components/CopyrightFooter";

const IMG = (imgName) => {
  return require(`../assets/images/icons/${imgName}`);
};

const iconStyle = {
  marginTop: "7%",
  width: "1.4em",
  paddingRight: "1.7%",
};

const About = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width:800px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  let fontWidth, fontSize, lineHeight, margTop;

  if (isMobileDevice) {
    fontWidth = "90%";
    fontSize = "1rem";
    lineHeight = "27px";
    margTop = "6%";
  } else if (isBigScreen) {
    fontWidth = "60%";
    fontSize = "1.3rem";
    lineHeight = "40px";
    margTop = "2.5%";
  } else {
    fontWidth = "60%";
    fontSize = "1rem";
    lineHeight = "27px";
    margTop = "2.5%";
  }

  const AboutMeText = {
    fontFamily: "RobotoMono",
    fontSize: fontSize,
    marginLeft: "5%",
    width: fontWidth,
    marginTop: margTop,
    lineHeight: lineHeight,
  };
  return (
    <div className="page">
      <div style={AboutMeText}>
        Hi I'm Noah. I'm a creative technologist and producer based in
        Brooklyn, NY. I like to create interactive and immersive environments,
        both digital and spacial, and have been building multimedia
        installations for the past five years. My work explores the constantly
        evolving relationship between the self and the system, whatever that
        means.
        <br />
        <br />I honed my knowledge of experiential design while at the{" "}
        <a
          className="aboutLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.labatrockwellgroup.com/"
        >
          {" "}
          LAB at Rockwell Group
        </a>{" "}
        where I produced a variety of temporary and permanent installations, as well as at{" "}
        <a
          className="aboutLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.de-yan.com/"
        >
          {" "} DE-YAN
          </a>{" "} where I built experiential projects of all mediums. I currently work at{" "}
        <a
          className="aboutLink"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instrument.com/"
        >
          {" "}
          Instrument
          </a>{" "}
          as a Senior Producer. I hold an MFA in Design & Technology from the Parsons School of Design and
        a B.A. in Urban Studies from Vassar College.
        <br />
        <br />
        This website represents a selection of my work.
        <div>
          <a
            href="mailto:noedelstein@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={iconStyle}
              className="icon"
              src={IMG("mail.png")}
              alt="email"
            />
          </a>
          <a href={Resume} target="_blank" rel="noopener noreferrer">
            <img
              style={iconStyle}
              className="icon"
              src={IMG("resume.png")}
              alt="resume"
            />
          </a>
          <a
            href="https://github.com/Nedelstein"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={iconStyle}
              className="icon"
              src={IMG("github.png")}
              alt="github"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/noahedelstein5/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={iconStyle}
              className="icon"
              src={IMG("linkedin.png")}
              alt="linkedin"
            />
          </a>
        </div>
      </div>
      <CopyRightFooter />
    </div>
  );
};

export default About;
