import React from "react";
import "./App.css";
import "video-react/dist/video-react.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import ScrollToTop from "./components/ScrollToTop";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect,
} from "react-router-dom";

import MainNav from "./components/MainNav";

//pages
import HomePage from "./pages";
import About from "./pages/About";
import Sketches from "./pages/Sketches";
import NotFoundPage from "./pages/404";

import CollectiveUnbelonging from "./pages/CollectiveUnbelonging";
import FakeMirror from "./pages/FakeMirror";
import MaskedContexts from "./pages/MaskedContexts";
import MFADTSite from "./pages/MFADTSite";
import MGMCotai from "./pages/MGMCotai";
import MOF from "./pages/MOF";
import Nobu from "./pages/Nobu";
import OBE from "./pages/OBE";
import ParticleSystemLetters from "./pages/ParticleSystemLetters";
import TheBoat from "./pages/TheBoat";
import Versace from "./pages/Versace";
import LensFest from "./pages/LensFest";
import LVNBA from "./pages/Lvnba";

function App() {
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };
  return (
    <Router>
      {/* <div className="App"> */}
      <ScrollToTop />
      <MainNav />
      <Route
        render={({ location }) => (
          <TransitionGroup>
            <CSSTransition key={location.key} timeout={600} classNames="fade">
              <Switch location={location}>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/404" component={NotFoundPage} />
                <Route exact path="/about" component={About} />
                <Route exact path="/sketches" component={Sketches} />
                <Route
                  exact
                  path="/collectiveUnbelonging"
                  component={CollectiveUnbelonging}
                />

                <Route exact path="/fakemirror" component={FakeMirror} />
                <Route
                  exact
                  path="/maskedcontexts"
                  component={MaskedContexts}
                />
                <Route exact path="/MFADTSite" component={MFADTSite} />
                <Route exact path="/mgmcotai" component={MGMCotai} />
                <Route exact path="/MOF" component={MOF} />
                <Route exact path="/nobu" component={Nobu} />
                <Route exact path="/obe" component={OBE} />
                <Route
                  exact
                  path="/ParticleSystemLetters"
                  component={ParticleSystemLetters}
                />
                <Route exact path="/theboat" component={TheBoat} />
                <Route exact path="/versace" component={Versace}/>
                <Route exact path="/lensfest" component={LensFest}/>
                <Route exact path="/lvnba" component={LVNBA}/>


                <Redirect to="/404" />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        )}
      />

      {/* </div> */}
    </Router>
  );
}

export default App;
